import React from 'react';
import './footer.css';

interface Link {
  title: string;
  href: string;
  onClick?: () => void;
}

export const Footer = (): JSX.Element => {
  const onEmailClick = () => {
      // base64 just to add one other layer of protection.
    const email: string = 'Y29udGFjdEFUYW5uZXhlZERPVGFwcA==';

    // format email
    const formatted = window.atob(email)
      .replace(`${window.atob('QVQ=')}`, '@')
      .replace(`${window.atob('RE9U')}`, '.');

    window.location.href = `mailto:${formatted}?subject=Hello!`;
  };


  const data: Link[] = [
    {
      title: 'contact',
      href: '#',
      onClick: onEmailClick,
    },
    {
      title: 'privacy',
      href: '#',
    },
    {
      title: 'terms',
      href: '#',
    },
  ];

  return (
    <footer className={'footer'}>
      <ul className={'footer-links'}>
        {
          data.map((link: Link): JSX.Element => (
            <li className={'footer-link'}>
              <a href={link.href} onClick={link.onClick}>
                <span>{link.title}</span>
              </a>
            </li>    
          ))
        }
      </ul>
    </footer>
  );
};
