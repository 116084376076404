import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// import for reset.css
import '../../styles/reset.css';
// layout styles
import './layout.css';
import Helmet from 'react-helmet';

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
      />
      <main>
        {children}
      </main>
    </>
  );
};
