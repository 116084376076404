import * as React from 'react';
import { AnnexedLogo } from '../icons';
// styles
import './content.css';

export const Content = (): JSX.Element => {
  return (
    <div className={'content-container'}>
      <AnnexedLogo className={'logo'} />
    </div>
  );
};
